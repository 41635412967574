import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/lotties/loadingAnimation.json';
import LOGO from '../assets/svg/LOGO.png'
function HeaderComponent({title}) {
const host = window.location.host

  return (
    <div className={host === 'locationfreight.com' ? `header_block` : `header_block_bottom`}>
                 <div className="header_block_container">
           <div className='header_logo'>
            {host === 'locationfreight.com' ?
                <Lottie animationData={loadingAnimation} loop={true} /> :
                <img src={LOGO} style={{width: 50, height: 50, marginTop: 5}} alt=''/>
          }
    

      </div>
           <div className='header_title_container '>
            <span className='header_title' style={{color: host !== 'locationfreight.com' ? 'white': 'black'}}>{title}</span>
           </div>
                      </div>
      </div>
  );
}

export default HeaderComponent;
