import React, {useState, useEffect} from 'react';
import Autocomplete from 'react-google-autocomplete';
import location_icon from '../../assets/svg/location_icon.png';
import distance_icon from '../../assets/svg/distance_icon.png';
import ReactSpeedometer from 'react-d3-speedometer';
import moment from 'moment';
import { ref, update } from 'firebase/database';
import { db } from '../../firebase/firebase'; 


const API_KEY = 'AIzaSyB_AkgpgYpDNiUyGWoQApq4NgYS44VKXRY'
const options={
  types: ['address'], // Ограничиваем поиск только адресами
  fields: ['formatted_address', 'geometry', 'address_components'], // Указываем, какие данные вернуть
  componentRestrictions: { country: 'us' }, // Ограничиваем результаты определенной страной (например, US)
}
const calculateRoadDistance = async (start, end) => {
  // Create a DirectionsService instance
  const directionsService = new window.google.maps.DirectionsService();

  return new Promise((resolve, reject) => {
    // Request for directions
    directionsService.route(
      {
        origin: new window.google.maps.LatLng(start.lat, start.lng),
        destination: new window.google.maps.LatLng(end.lat, end.lng),
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          // Calculate total distance from all legs in the route
          const route = response.routes[0];
          let totalDistance = 0;

          route.legs.forEach(leg => {
            totalDistance += leg.distance.value; // Distance in meters
          });

          // Convert distance to kilometers
          const distanceInKm = totalDistance / 1000;

          // Resolve the promise with the calculated distance
          resolve(distanceInKm);
        } else {
          console.error('Directions request failed due to ' + status);
          // Reject the promise with an error message
          reject(new Error('Directions request failed'));
        }
      }
    );
  });
};

function AdminPanelEdit({setPanel, selectedPlace}) {
  const [addressFrom, setAddress] = useState(selectedPlace.addressFrom);
  const [addressTo, setAddressTo] = useState(selectedPlace.addressTo);
  const [speed, setSpeed] = useState(selectedPlace.speed);
  const [title, setTitle] = useState(selectedPlace.title);
  const [distance, setdistance] = useState(selectedPlace.distance);
  const [bannerTitle, setBannerTitle] = useState(selectedPlace.bannerTitle);
  const [coordinatesFrom, setCoordinates] = useState({lat: selectedPlace.coordinatesFrom.lat, lng: selectedPlace.coordinatesFrom.lng});
  const [coordinatesTo, setCoordinatesTo] = useState({lat: selectedPlace.coordinatesTo.lat, lng: selectedPlace.coordinatesTo.lng});

  useEffect(() => {
    const fetchCoordinates = async () => {
        try {
          const coordinates = await calculateRoadDistance(coordinatesFrom, coordinatesTo);
          setdistance(coordinates);
          // You can now use the coordinates array as needed
        } catch (error) {
          console.error(error);
        }
      };
      if(coordinatesFrom.lat && coordinatesTo.lat){
        fetchCoordinates()
      }
    }, [coordinatesTo]);
   useEffect(() => {
if(speed || addressFrom || addressTo){
  calculateFutureDate()
}
   }, [speed, addressFrom, addressTo])
  const speedChange = e => {
    if(+e.target.value > 60 && +e.target.value < 201){
    setSpeed(+e.target.value);
    }else{
      setSpeed(60)
    }
  };
  const onTextChange = e => {
    setTitle(e.target.value);
  };
  const onBannerTextChange = e => {
    setBannerTitle(e.target.value);
  };
  const calculateFutureDate = () => {
    if (distance === null && speed === undefined) {
      return null;
    }
    const currentDate = moment(); // Get the current date and time
    const futureDate = currentDate.add(distance / speed, 'hours'); // Add timeInHours to the current date and time
    return moment(futureDate).format('dddd, MMMM Do YYYY, h:mm:ss A'); // Return the future date as a JavaScript Date object
  };
  const futureDate = calculateFutureDate();

  const editTruck = () => {
  
    update(ref((db), `/${selectedPlace.id}`),{
      addressFrom: addressFrom,
      coordinatesFrom: coordinatesFrom,
      addressTo: addressTo,
      coordinatesTo: coordinatesTo,
      speed: speed,
      distance: distance,
      futureDate: futureDate,
      bannerTitle: bannerTitle,
      title: title,
      isPaused: false
    })
    setPanel('all')
  };
  const totalSeconds = (distance / speed) * 3600;

// Create a moment duration from the total seconds
const duration = moment.duration(totalSeconds, 'seconds');

// Extract hours, minutes, and seconds
const hours = Math.floor(duration.asHours());
const minutes = duration.minutes();
const seconds = duration.seconds();


  return (
    <div>
      <span className="title_1">Edit</span>
      <div className="adding_box">
        <span className="title_2">Adress From</span>
        <div className="adding_box_content">
          <Autocomplete
            apiKey={API_KEY} // replace with your Google Maps API key
            onPlaceSelected={(places) => {
              if(places){
                  setAddress(places.formatted_address);
                  setCoordinates({
                    lat: places.geometry?.location.lat(),
                    lng: places.geometry?.location.lng(),
                  });
            }
            }}
            options={options}
            placeholder={selectedPlace.addressFrom}
            className="autoComplite_input"
          />
          {addressFrom && (
            <div className="adress_text">
              <img src={location_icon} style={{width: 30, height: 30}} />
              <span className="title_2">{addressFrom}</span>
            </div>
          )}
        </div>
      </div>
      <div className="adding_box">
        <span className="title_2">Adress To</span>
        <div className="adding_box_content">
          <Autocomplete
            apiKey={API_KEY}// replace with your Google Maps API key
            onPlaceSelected={(places) => {
              if(places){
                setAddressTo(places.formatted_address);
                setCoordinatesTo({
                  lat: places.geometry?.location.lat(),
                  lng: places.geometry?.location.lng(),
                });
              }
          
            }}
              options={options}
            placeholder={selectedPlace.addressTo}
            className="autoComplite_input"
          />
          {addressTo && (
            <div className="adress_text">
              <img src={location_icon} style={{width: 30, height: 30}} />
              <span className="title_2">{addressTo}</span>
            </div>
          )}
        </div>
      </div>
      <div className='titles_inputs'>
      <div className="adding_box w_50">
        <span className="title_2">Title</span>
        <div className="adding_box_speed w_90">
          <input
            value={title}
            onChange={onTextChange}
            placeholder="Title"
            className="autoComplite_input" w_90
          />
        </div>
      </div>
      <div className="adding_box w_50">
        <span className="title_2">Banner title</span>
        <div className="adding_box_speed w_90">
          <input
            value={bannerTitle}
            onChange={onBannerTextChange}
            placeholder="Banner title"
            className="autoComplite_input w_90"
          />
        </div>
      </div>
      </div>
      <div className="adding_box">
        <span className="title_2">Speed km/h</span>
        <div className="adding_box_speed">
          <input
            onChange={speedChange}
            placeholder={`${speed} km/h`}
            className="autoComplite_input speed_input"
          />
            <ReactSpeedometer
              value={speed}
              minValue={60}
              maxValue={200}
              currentValueText={`${speed} km/h`}
              height={190}
              segmentColors={[
                '#77FFCC',
                '#2a9df4',
                '#cce7c9',
                '#f69697',
                '#f94449',
              ]}
              customSegmentLabels={[
                {
                  text: '60km/h',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '12px',
                },
                {
                  text: '100km/h',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '12px',
                },
                {
                  text: '130km/h',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '12px',
                },
                {
                  text: '160km/h',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '12px',
                },
                {
                  text: '200km/h',
                  position: 'INSIDE',
                  color: '#555',
                  fontSize: '12px',
                },
              ]}
            />
        </div>
      </div>
      {addressTo && addressTo && speed && futureDate && title ? (
        <div className="adding_box">
          <div className="all_datas">
            <img
              src={distance_icon}
              style={{width: 50, height: 50, marginRight: '20px'}}
            />
            <div className="distance_futureDate">
              <div>
                <span
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    paddingRight: '20px',
                    fontWeight: '900',
                  }}>
                  Distance:
                </span>
                <span className="title_2">{distance.toFixed(2)} km</span>
              </div>
              <div style={{paddingTop: '5px', paddingBottom: '5px'}}>
                <span
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    paddingRight: '20px',
                    fontWeight: '900',
                  }}>
                  Total Hour:
                </span>
                <span className="title_2">{hours} hours, {minutes} minutes, and {seconds} seconds</span>
              </div>
              <div>
                <span
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    paddingRight: '20px',
                    fontWeight: '900',
                  }}>
                  Posible finish date (after Create):
                </span>
                <span className="title_2">{futureDate}</span>
              </div>
            </div>
          </div>
          <div className='flex_just'>
          <button className="login_button" onClick={editTruck}>
            <span className="text_1">Edit</span>
          </button>
          {/* <button className="login_button" onClick={() =>setPanel('all')}>
            <span className="text_1">All Trucks</span>
          </button> */}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AdminPanelEdit;
