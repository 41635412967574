import React, { useEffect, useState } from 'react';
import AdminPage from './adminComponents/AdminPage';
import AdminLogin from './adminComponents/AdminLogin'




function MainAdimPage() {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
      const adminValue = localStorage.getItem('admin');
      if (adminValue === 'true') {
        setIsAdmin(true);
      }
    }, []);

 if(isAdmin){
    return <AdminPage />
 }else{
    return <AdminLogin setIsAdmin={setIsAdmin}/>
 }
}

export default MainAdimPage;
