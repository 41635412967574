import React,{useEffect} from 'react';
import { BrowserRouter} from "react-router-dom";
import RouterComponent from './components/RouterComponent';
import LOGO from './assets/svg/LOGO.png'

function App() {
  const host = window.location.host
  useEffect(() => {
    document.title = host === 'locationfreight.com' ? "Location Freight" : '365 Track Me';
    if( host !== 'locationfreight.com'){
    function changeFavicon(iconURL) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = iconURL;
    }
    
    // Call the function to change the favicon
    changeFavicon(LOGO);
  }
  }, []);

  return (
    <BrowserRouter>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
