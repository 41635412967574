import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import UserPageMap from "./UserPageMap";
import MainAdimPage from "./MainAdimPage";
import ClientLoading from "./loadingComponents/ClientLoading";

function RouterComponent() {
  const location = useLocation().pathname;

if(location === '/'){
  return <ClientLoading isValid={'Trust us with your cargo!'} />
}
  return (
    <Routes>
      <Route
        path={location === "/admin" ? "/admin" : location}
        element={
          location === "/admin" ? (
            <MainAdimPage />
          ) : (
            <UserPageMap location={location.replace("/", "")} />
          )
        }
      >
        {location === "/admin" ? (
          <Route index element={<MainAdimPage />} />
        ) : (
          <Route
            index
            element={<UserPageMap location={location.replace("/", "")} />}
          />
        )}
      </Route>
    </Routes>
  );
}

export default RouterComponent;
