import React, { useState } from 'react';
import '../../styles/globalStyles.css';

function AdminLogin({setIsAdmin}) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  // Handle password input change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const _loginPress = () => {
    if (login === 'Erebuni' && password === 'pistalet9mm') {
      setTimeout(() => {
        localStorage.setItem('admin', 'true');
        setIsAdmin(true) 
      }, 500);
    } else {
      setError('Invalid credentials');
    }
  }
 
  return (
    <div className="admin_main_container">
      <div className="admin_left_bg_img" />
      <div className="admin_rigt_bg_img" />
      <div className="black_bg_op" />
      <div className="loginCont">
        <div style={{padding: 15}}>
          <span className="title_1">Admin Login</span>
          <div className="login_input_cont">
            <span className="text_1">Login</span>
            <input
              type="text"
              placeholder="Enter Login"
              className="login_input"
              value={login}
              onChange={handleLoginChange}
            />
          </div>
          <div className="login_input_cont">
            <span className="text_1">Password</span>
            <input
              type="password"  // Change type to password for better security
              placeholder="Enter Password"
              className="login_input"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div style={{paddingTop: '20px'}}>
            {error && <span style={{color: 'red'}}>{error}</span>}
          </div>
          <button className="login_button" onClick={_loginPress} disabled={login && password ? false : true}>
          <span className="text_1">Enter</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
