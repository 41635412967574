import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBfVfcwnfTtnDUC2DjU-l1Q2HM7zIPPeSM",
    authDomain: "motive-truck.firebaseapp.com",
    projectId: "motive-truck",
    storageBucket: "motive-truck.appspot.com",
    messagingSenderId: "913166565744",
    appId: "1:913166565744:web:242c1fdbdd1098e37b8fb5",
    measurementId: "G-ZL1MBQ6EGK"
  };

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)